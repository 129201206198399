import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/components/home/home.vue';

Vue.use(Router);
export const router = new Router({
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            children: [
                {
                    path: ':country?/:units?',
                    name: 'Home',
                    meta: {
                        requiresAuthentication: true,
                    },
                    component: Home,
                },
                {
                    path: ':zoomed?/:country?/:units?',
                    name: 'ChartZoomed',
                    meta: {
                        requiresAuthentication: true,
                    },
                    component: Home,
                },
            ],
            meta: {
                requiresAuthentication: true,
            },
            component: Home
        },
    ],
});

export default router;
