const generatedDataChartConfig = (id: string, firstTimeStamp: Date, max: number, unitName: string): any => {
    return {
        chart: {
            id,
            type: 'line',
            zoom: {
                enabled: false,
            },
            animations: {
                enabled: false,
            },
            toolbar: {
                export: {
                    csv: {
                        filename: unitName,
                        dateFormatter: (val: Date): string => {
                            return val.toISOString().replace(/T/, ' ').replace(/\..+/, '');
                        },
                    },
                },
            },
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'right',
        },
        grid: {
            show: true,
            yaxis: {
                lines: {
                    show: true,
                },
            },
            xaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                top: 50,
            },
        },
        colors: [ '#5CBCF5', '#08A1EA', '#00E396', '#FEAF19', ],
        stroke: {
            curve: 'smooth',
            width: [ 2, 4, 3, 3 ],
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 0,
        },
        fill: {
            type: 'gradient',
            gradient: {
                stops: [ 0, 100 ],
                opacityFrom: 0,
                opacityTo: 0,
            },
        },
        yaxis: {
            labels: {
                formatter: function (val: string): string {
                    return val;
                },
            },
            title: {
                text: 'MW',
            },
            min: 0,
            max: max + max * 0.1,
            forceNiceScale: true,
        },
        xaxis: {
            type: 'datetime',
            min: firstTimeStamp.getTime(),
            max: new Date(new Date().setDate(new Date().getDate() + 2)).getTime(),
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm',
                },
                datetimeUTC: false,
            },
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val: string): string {
                    return `${Number(val).toFixed(0)} MW`;
                },
            },
            x: {
                format: 'dd MMM HH:mm',
            },
        },
    };
};

export default generatedDataChartConfig;
