<template>
    <div
        :class="[{ 'full-height-chart' : numberOfChart < 3 }]"
        class="wrapper-apexchart">
        <adam-card :config.prop="cardConfig">
            <span slot="title">
                <div class="flex-row">
                    <div class="flex-col flex-4">
                        {{ chartData.name }}
                    </div>
                    <div>
                        <div
                            v-if="alertDataAvailablePi"
                            class="wrapper-icon">
                            <font-awesome-icon icon="exclamation-triangle" class="icon-available-triangle"/>
                        </div>
                        <div
                            v-if="alertPi"
                            class="wrapper-icon">
                            <font-awesome-icon icon="exclamation-triangle" class="icon-exclamation-triangle"/>
                        </div>
                    </div>
                    <div>
                        <div
                            class="chart-control"
                            @click="onToggleChartMenu()">
                            <img src="@/assets/icons/ic_download.svg" width="20" height="20">
                            {{ $t('download') }}
                        </div>
                        <div
                            class="chart-control"
                            @click="onToggleExpand()">
                            <img src="@/assets/icons/ic_fullview.svg" width="20" height="20">
                            <span v-if="this.$route.query.zoomed">
                                {{ $t('collapse') }}
                            </span>
                            <span v-else>
                                {{ $t('fullView') }}
                            </span>
                        </div>
                    </div>
                </div>
            </span>
            <span slot="content">
                <div
                    :class="[{ 'full-width-chart' : $route.query.zoomed }]"
                    class="chart"
                    v-if="series.length > 0">
                    <apex-chart
                        type="area"
                        width="100%"
                        height="100%"
                        :options="options"
                        :series="series" />
                </div>
                <div
                    :class="[{ 'full-width-chart' :  $route.query.zoomed }]"
                    class="chart-legend"
                    v-if="series.length > 0">
                    <apex-chart
                        type="area"
                        width="100%"
                        height="100%"
                        :options="timelapseOptions"
                        :series="series" />
                    <div class="helper-tooltip">
                        {{ $t('secondaryChartHelpText') }}
                    </div>
                </div>
            </span>
        </adam-card>
    </div>
</template>

<script lang="ts">

import { Component, Vue, Prop } from 'vue-property-decorator';
import { AdamCardConfig } from 'adam.ui-core/dist/src/lib/components/card';
import generatedDataChartConfig from './generated-data-chart-config';
import timelapseChartConfig from './timelapse-chart-config';
import { SeriesValue, UnitChartModel } from '@/models';

@Component({
    name: 'chart',
})
export default class Chart extends Vue {
    /**
     * Unit and generation data to be passed to the chart.
     */
    @Prop()
    private chartData!: UnitChartModel;

    /**
     * Number of the chart in the list of all charts.
     */
    @Prop()
    private numberOfChart!: number;

    private cardConfig: AdamCardConfig = {
        cardClass: 'chart__card',
        headerClass: 'chart__header',
        contentClass: 'chart__card-content',
        actionClass: '',
        hasAction: false,
    };
    private series: any[] = [];
    private options = generatedDataChartConfig(
        this.chartData.unitSid,
        this.chartData.generationData.availability.length > 0 ? this.chartData.generationData.availability[0].x : new Date(),
        this.chartData.generationData.netCapacity.length > 0 ? this.chartData.generationData.
            netCapacity[this.chartData.generationData.netCapacity.length - 1].y : 0,
        this.chartData.name);
    private timelapseOptions = timelapseChartConfig(this.chartData.unitSid);

    private mounted(): void {
        this.$nextTick(() => {
            this.series = [
                {
                    name: 'Net Capacity',
                    data: this.chartData.generationData.netCapacity,
                },
                {
                    name: 'Generated Power',
                    data: this.chartData.generationData.generatedPower,
                },
                {
                    name:  'Availability',
                    data: this.chartData.generationData.availability,
                },
                {
                    name:  'PMIN',
                    data: this.chartData.generationData.pMin,
                },
            ];
        });
    }

    private onToggleExpand(): void {
        if (!this.$route.query.zoomed) {
            this.$router.push({
                name: 'ChartZoomed',
                params: {
                    country: this.$route.params.country,
                    units: this.$route.params.units,
                },
                query: {
                    zoomed: this.chartData.unitSid.toString(),
                },
            });
        } else {
            this.goToHomeRoute();
        }
    }

    private goToHomeRoute(): void {
        this.$router.push({name: 'Home', params: {country: this.$route.params.country, units: this.$route.params.units}});
    }

    private onToggleChartMenu(): void {
        const chartEl = document.getElementById(`apexcharts${this.chartData.unitSid}`);
        if (chartEl) {
            const menu = chartEl.getElementsByClassName('apexcharts-menu')[0];
            const openMenuClass = 'apexcharts-menu-open';
            if (menu.classList.contains(openMenuClass)) {
                menu.classList.remove(openMenuClass);
            } else {
                menu.classList.add(openMenuClass);
            }
        }
    }

    get alertPi(): boolean {
        const generatedPower = this.chartData.generationData.generatedPower;
        const availability = this.chartData.generationData.availability;
        if (generatedPower && generatedPower.length > 1) {
            const indexLastGeneratedPower = generatedPower.
                filter((d: SeriesValue) => d.y !== null).length - 1;
            return (generatedPower[indexLastGeneratedPower].y >
                availability[indexLastGeneratedPower].y +
                availability[indexLastGeneratedPower].y * 0.1);
        }
        return false;
    }

    get alertDataAvailablePi(): boolean {
        const generatedPower = this.chartData.generationData.generatedPower;
        if (generatedPower && generatedPower.length > 0) {
            const lastEntry = new Date(generatedPower[generatedPower.length - 1].x);
            const now = new Date();
            const difference = now.getTime() - lastEntry.getTime();
            const differenceInMinutes = Math.floor(difference/1000/60);
            return differenceInMinutes > 15;
        }
        return false;
    }
}

</script>

<style lang="less" scoped>
@import '../../variables.less';
.wrapper-apexchart {
    flex: 40%;
    min-width: 30rem;
    margin: 0rem 2rem 4rem 2rem;
    /deep/ .chart {
        &__card {
            padding: 0;
            border-radius: 6px;
            border: 1px solid #EAEAEA;
        }
        &__header {
            background: @light-grey;
            color: black;
            padding: 1.5rem 2rem;
            margin-bottom: 0;
            height: 5rem;
            border-bottom: 1px solid #E8E8E8;
            span {
                display: block;
            }
            .chart-control {
                -moz-user-select: none;
                -webkit-user-select: none;
                font-size: 1.4rem;
                cursor: pointer;
                color: black;
                font-weight: normal;
                display: inline-block;
                border-left: 1px solid #E5E5E5;
                padding: 0 1rem;
                img {
                    margin-right: 1rem;
                    vertical-align: middle;
                }
                span {
                    display: inline;
                }
            }
        }
        &__card-content {
            padding: 0 2rem 1rem 2rem;
        }
    }
    .wrapper-icon {
        margin-right: 1rem;
        display: inline-block;
    }
    .icon-exclamation-triangle {
        color: @red-darker;
    }
    .icon-available-triangle {
        color: @amber-lighter;
    }
    .chart {
        height: 25rem;
    }
    .chart-legend {
        height: 13rem;
        position: relative;
        .helper-tooltip {
            position: absolute;
            bottom: -2rem;
            visibility: hidden;
            background: #333;
            border-radius: 0.6rem;
            padding: 1rem 2rem;
            color: white;
            &::after {
                content: " ";
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent #333 transparent;
                }
        }
        &:hover {
            cursor: pointer;
            .helper-tooltip {
                visibility: visible;
            }
        }
    }
    &.full-height-chart {
        .chart {
            height: 56rem;
        }
        .chart-legend {
            height: 20rem;
        }
    }
    .full-width-chart {
        &.chart {
            height: 56rem;
        }
        &.chart-legend {
            height: 20rem;
        }
    }
}

</style>
