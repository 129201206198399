import { Unit } from '@/models';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const applicationState: {
    isLoading: boolean;
    selectedUnits: Unit[];
} = {
    isLoading: false,
    selectedUnits: [],
};

export default new Vuex.Store({
    state: applicationState,
    mutations: {
        loading(state): void {
            state.isLoading = !state.isLoading;
        },
        selectedUnits(state, units): void {
            state.selectedUnits = units;
        },
        unitsLoaded(state, units): void {
            state.selectedUnits = units;
        },
    },
    actions: {
        unitsLoaded({ commit }, units): void {
            commit('unitsLoaded', units);
        },
    },
    modules: {},
    getters: {
        isLoading(state): boolean {
            return state.isLoading;
        },
        selectedUnits(state): Unit[] {
            return state.selectedUnits;
        },
    },
});
