<template>
    <div class="header-wrapper">
        <adam-header-bar
            :userName="user.name"
            :emailAddress="user.email"
            :showNotifications.prop="false"
            :class="[
                { 'bg-dev': isDevelopmentEnv },
                { 'bg-uat': isUatEnv },
            ]"
            @apps-click="clickDrawer"
        >
            <span slot="headerContent" class="app-logo d-none-mobile">
                <a href="/">
                    <img
                        class="app-logo-img"
                        src="~adam.ui-core/assets/brand/logo-coode-white.svg"
                    >
                </a>
            </span>
            <span slot="headerContent">
                <h4 class="app-title">{{$t('liveGeneration')}}</h4>
                <div class="ml-1">
                    <select-input
                        v-if="countries.length > 0"
                        :options="countries"
                        :default-option="this.$route.params.country"
                        @on-change="onCountryChange" />
                </div>
                <div class="ml-1">
                    <multi-select-input
                        v-if="units.length > 0"
                        :options="units"
                        @apply-selected-options="onUnitsChange" />
                </div>
            </span>
            <span slot="profileDetails">
                <div class="flex flex-col">
                    <pui-button state="secondary" icon="report" class="profile-action-button" @click="sendFeedback()">
                        {{ $t('sendFeedback') }}
                    </pui-button>
                    <pui-button
                        state="secondary"
                        icon="warning"
                        class="profile-action-button profile-action-button--important"
                        @click="reportIncident()"
                    >
                        {{ $t('reportIncident') }}
                    </pui-button>
                    <pui-button icon="power" class="profile-action-button" @click="logOut()">
                        {{ $t('signOut') }}
                    </pui-button>
                </div>
            </span>
        </adam-header-bar>
        <app-drawer
            ref="appDrawer"
            :data.prop="applications"
            :labels.prop="getAppDrawerLabels"
            :onClick.prop="handleAppClick"
        >
            <span slot="appLogo">
                <img src="~adam.ui-core/assets/brand/logo-coode-rgb.svg" />
            </span>
        </app-drawer>
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import MultiSelectInput from '@/components/multi-select-input/multi-select-input.vue';
import SelectInput from '@/components/select-input/select-input.vue';
import { AppService, MasterDataService } from '@/services';
import { Application, Country, Unit } from '@/models';
import { EventBus } from '@/utils';
import { sdk } from '@/utils/sdk';

@Component({
    name: 'header-wrapper',
    components: {
        selectInput: SelectInput,
        multiSelectInput: MultiSelectInput,
    },
})
export default class HeaderWrapper extends Vue {
    private masterDataService: MasterDataService = new MasterDataService();
    private appService: AppService = new AppService();
    private applications: MenuEntry[] = [];
    private countries: Country[] = [];
    private units: Unit[] = [];
    private user = {
        name: '',
        email: '',
    };
    private appStoreUrl: string | undefined = process.env.VUE_APP_APPSTORE_UI_URL;
    private useCaseId: string | undefined = process.env.VUE_APP_USE_CASE_ID;

    private async created(): Promise<void> {
        await this.init();
    }

    private async init(): Promise<void> {
        await this.setProfileData();
        await this.loadApps();
        await this.loadCountries();
        if (!this.$route.params.country) {
            this.$route.params.country = this.countries[0].id.toString();
        }
        if (!this.$route.params.units) {
            await this.loadUnits(this.countries[0].id);
            this.$router.push({name: 'Home', params:{country: this.$route.params.country, units: this.unitSids.join(',')}});
        }
        await this.loadUnits(Number(this.$route.params.country));
    }

    private async setProfileData(): Promise<void> {
        const profile = sdk?.localStorage.getPermissionsLocalStorage()
        if (profile) {
            this.user.name = profile?.name;
            this.user.email = profile?.email;
        }
    }

    private async loadApps(): Promise<void> {
        this.$store.commit('loading');
        try {
            const apps = (await this.appService.getApps());
            this.applications = this.processApps(apps);
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingApplications');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private processApps(apps: Application[]): MenuEntry[] {
        return apps.map((app: Application): MenuEntry => ({
            id: app.useCaseId,
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        })).sort((a: MenuEntry, b: MenuEntry) => {
            const nameA = a.label.toLowerCase();
            const nameB = b.label.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    private clickDrawer(): void {
        (this.$refs.appDrawer as any).open = true;
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url);
        }
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    private logOut(): void {
        sdk?.auth.logout();
    }

    private sendFeedback(): void {
        window.open(`${this.appStoreUrl}/contact-page/${this.useCaseId}?isFeedback=true`, '_blank');
    }

    private reportIncident(): void {
        window.open(`${this.appStoreUrl}/incident-page/${this.useCaseId}`, '_blank');
    }

    get getAppDrawerLabels(): any {
        return { applications: this.$i18n.t('appsWithAccess'), miscellaneous: this.$i18n.t('appsWithoutAccess') };
    }

    private async onUnitsChange(unitSids: number[]): Promise<void> {
        if (unitSids.join(',') !== this.$route.params.units) {
            this.$router.push({name: 'Home', params: { country: this.$route.params.country, units: unitSids.join(',') } });
            await this.loadUnits(Number(this.$route.params.country));
        }
    }

    private goHomeRoute(): void {
        if (this.$route.path !== '/' || this.$route.params.country) {
            this.$router.push({name: 'Home'});
        }
    }
    private async onCountryChange(countrySid: number): Promise<void> {
        await this.loadUnits(countrySid);
        this.$route.params.country = countrySid.toString();
        this.$router.push({name: 'Home', params:{country: this.$route.params.country, units: this.unitSids.join(',')}});
    }

    private async loadCountries(): Promise<void> {
        this.countries = [];
        try {
            this.countries = (await this.masterDataService.getCountry()).result.items;
        } catch(err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingCountries');
            throw err;
        }
    }

    private async loadUnits(countryId: number): Promise<void> {
        this.units = [];
        try {
            this.units = (await this.masterDataService.getUnits(countryId)).result
                .items.map((u: Unit): Unit => {
                    if (countryId === 4100) {
                        return {...u, ...{ name: u.abbreviation }};
                    }
                    return {...u, ...{ name: u.businessName }};
                });
            this.$store.dispatch('unitsLoaded', this.units);
        } catch(err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingUnits');
            throw err;
        }
    }

    get unitSids(): number[] {
        const unitSids: number[] = [];
        this.units.forEach((u: Unit) => {
            unitSids.push(u.id);
        });
        return unitSids;
    }

    private get isDevelopmentEnv(): boolean {
        return ['dev', 'development'].includes(process.env.VUE_APP_ENVIRONMENT as string);
    }

    private get isUatEnv(): boolean {
        return process.env.VUE_APP_ENVIRONMENT === 'uat';
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';
.header-wrapper {
    .app-logo {
        a {
            height: inherit;
        }

        img {
            height: 75%;
            margin-right: 1rem;
            padding: 6px 6px 3px 6px;
        }
    }

    .app-title {
        font-weight: normal;
    }

    .profile-action-button {
        text-transform: none;
        width: 100%;
        border-radius: 0;
        margin-bottom: 2px;
        height: 3.6rem;

        &--important {
            border: 1px solid @contextual-red !important;
            color: @contextual-red !important;
            &:hover {
                color: @dark-red !important;
                border: 1px solid @dark-red !important;
            }
        }
    }

    @media (max-width: 767.98px) {
        .d-none-mobile {
            display: none;
        }
    }
}

::v-deep .adam-header__separator.adam-header-bar {
    background: white;
}

::v-deep .bg-dev {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @uniper-dev;

        .adam-header-user__icon.adam-header-bar path {
            fill: @uniper-dev;
        }

        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-dev-darker;
        }

        .adam-header__logo.adam-header-bar:hover {
            --fill: @uniper-dev-darker;
        }
    }
}

::v-deep .bg-uat {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @uniper-uat;

        .adam-header-user__icon.adam-header-bar path {
            fill:@uniper-uat;
        }

        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-uat-darker;
        }

        .adam-header__logo.adam-header-bar:hover {
            --fill: @uniper-uat-darker;
        }
    }
}
</style>
