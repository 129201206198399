<template>
    <div class="home-page">
        <div class="flex-row timer" v-if="!$store.getters.isLoading">
            <div class="legend">
                <font-awesome-icon icon="exclamation-triangle" class="icon-available-triangle"/>
                    <span>- {{$t('dataNotAvailable')}}</span>
                <font-awesome-icon icon="exclamation-triangle" class="icon-exclamation-triangle"/>
                    <span>- {{$t('piGreaterThan10')}}</span>
            </div>
            <timer value="300" @reload="reload"/>
        </div>
        <div
            class="flex-row wrapper-chart"
            v-for="(page, key, index) in generationDataPaged"
            :key="index">
            <div
                :key="index"
                v-show="index + 1 === userPaging.currentPage"
                class="chart-wrapper-page">
                <chart
                    v-for="(chart, chartIndex) in page"
                    :key="`${chart.unitSid}-${chartIndex}`"
                    :number-of-chart="generationDataPaged[userPaging.currentPage].length"
                    :chart-data="chart">
                </chart>
                <div
                    v-if="page.length % 2 > 0 && !$route.query.zoomed"
                    class="chart-space-filler">
                </div>
            </div>
        </div>
        <div class="flex-row wrapper-pagination">
            <paging-component
                v-if="!$route.query.zoomed && filteredGenerationData.length > 4"
                :paging="userPaging"
                @paginate="paginate" />
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Vue, Watch } from 'vue-property-decorator';
import { GenerationData, GenerationDataValues, PagingModel,
    SeriesValue, Unit, UnitChartModel, UnitChartModelValue } from '@/models';
import PagingComponent from '@/components/paging-component/paging-component.vue';
import Chart from '@/components/chart/chart.vue';
import Timer from '@/components/timer/timer.vue';
import { GenerationDataService } from '@/services';
import { EventBus } from '@/utils/eventbus';

@Component({
    name: 'home',
    components: {
        chart: Chart,
        pagingComponent: PagingComponent,
        timer: Timer,
    },
})
export default class Home extends Vue {
    private pagedFilterModel: any = {
        pageNr: 1,
        pageSize: 4,
    };
    private userPaging: PagingModel = {
        currentPage: this.pagedFilterModel.pageNr,
        numberOfPages: 0,
        pages: [],
    };
    private generationDataService: GenerationDataService = new GenerationDataService();
    private generationData: GenerationData[] = [];
    private units: Unit[] = [];
    private generationDataPaged: any = {};

    @Watch('$route.query')
    private async onQueryChange(newVal: string, oldVal: string): Promise<void> {
        if (newVal !== oldVal && this.$route.params.units) {
            this.$store.commit('loading');
            await this.init();
            window.location.reload();
            this.$store.commit('loading');
        }
    }

    private async mounted(): Promise<void> {
        this.$store.subscribe(async (mutation: any) => {
            if (mutation.type === 'unitsLoaded') {
                this.$store.commit('loading');
                await this.reloadUnits();
                await this.init();
                this.$store.commit('loading');
            }
        });
    }

    private async reload(): Promise<void> {
        await this.reloadUnits();
        await this.init();
    }

    private async init(): Promise<void> {
        this.generationDataPaged = {};
        this.units = this.$store.getters.selectedUnits;
        if (this.$route.query.zoomed) {
            const zoomedUnitSid = Number(this.$route.query.zoomed);
            this.units = this.$store.getters.selectedUnits.filter((u: Unit) => u.id === zoomedUnitSid);
        }

        this.$nextTick(() => {
            this.setupPaging(this.generationData.length);
            this.buildPagedData();
        });
    }

    private async loadGenerationData(unitSids: number[]): Promise<void> {
        try {
            this.generationData = (await this.generationDataService.getGenerationData(unitSids, 1, 7)).result.items;
        } catch(err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            throw err;
        }
    }

    private buildPagedData(): void {
        const processedGenerationData: UnitChartModel[] = [];
        for (const unit of this.units) {
            let chartTitle = '';
            if (this.$route.params.country === '4100') {
                chartTitle = unit.abbreviation;
            } else {
                chartTitle = unit.businessName;
            }
            const unitGenerationData = this.generationData.
                find((g: GenerationData) => g.unitSid === unit.id);
            if (unitGenerationData) {
                processedGenerationData.push({
                    name: chartTitle,
                    unitSid: unit.id.toString(),
                    generationData: this.buildSeries(unitGenerationData.generationData),
                });
            }
        }
        const generationDataPaged: any = {};
        for (let i = 0; i < this.userPaging.numberOfPages; i++) {
            generationDataPaged[i + 1] = processedGenerationData.slice((this.pagedFilterModel.pageNr + i - 1) *
                this.pagedFilterModel.pageSize, this.pagedFilterModel.pageSize * (this.pagedFilterModel.pageNr + i));
        }
        this.generationDataPaged = generationDataPaged;
    }

    private buildSeries(generationData: GenerationDataValues[]): UnitChartModelValue {
        const series: UnitChartModelValue = {
            pMin: [],
            generatedPower: [],
            availability: [],
            netCapacity: [],
        };

        generationData.forEach((data: GenerationDataValues ): void => {
            series.pMin.push(this.buildSeriesEntry(data.timeStamp, data.pMin));
            series.availability.push(this.buildSeriesEntry(data.timeStamp, data.availability));
            series.netCapacity.push(this.buildSeriesEntry(data.timeStamp, data.netCapacity));
            if (new Date(data.timeStamp) <= new Date() && data.generatedPower !== null) {
                series.generatedPower.push(this.buildSeriesEntry(data.timeStamp, data.generatedPower));
            }
        });

        return series;
    }

    private buildSeriesEntry(timeStamp: string, value: number): SeriesValue {
        return { x: new Date(timeStamp), y: parseFloat(value?.toFixed(2)) };
    }

    private resetPaging(): void {
        this.paginate(1);
        this.setupPaging(this.filteredGenerationData.length);
        this.userPaging.currentPage = 1;
    }

    private setupPaging(totalCount: number): void {
        this.userPaging.pages = [];
        const numberOfPages = totalCount / this.pagedFilterModel.pageSize;
        const hasDecimal = numberOfPages - Math.floor(numberOfPages) > 0;
        this.userPaging.numberOfPages = hasDecimal ? Math.floor(numberOfPages) + 1 : numberOfPages;
        for (let i = 1; i <= this.userPaging.numberOfPages; i++) {
            this.userPaging.pages.push(i);
        }
    }

    private paginate(page: number): void {
        this.pagedFilterModel.pageNr = page;
    }

    get filteredGenerationData(): GenerationData[] {
        if (this.$route.query.zoomed) {
            return this.generationData.filter((g: GenerationData) => g.unitSid === Number(this.$route.query.zoomed));
        } else {
            return this.generationData;
        }
    }

    private async reloadUnits(): Promise<void> {
        if (this.$route.params.units) {
            await this.loadGenerationData(this.$route.params.units.split(',').map((e) => Number(e)));
            this.resetPaging();
        }
    }
}

</script>

<style lang="less" scoped>
@import '../../variables.less';
.timer {
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 2rem;
}
.legend {
    padding: 2rem 0;
    span {
        margin-right: 2rem;
    }
}
.legend, .last-refreshed {
    margin: 0 2rem;
}
.icon-exclamation-triangle {
    color: @red-darker;
    margin-right: 0.5rem;
}
.icon-available-triangle {
    color: @amber-lighter;
    margin-right: 0.5rem;
}
.wrapper-chart {
    padding: 0 2rem;
}
.wrapper-pagination {
    justify-content: center;
}
.chart-wrapper-page {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: inherit;
}
.chart-space-filler {
    flex: 40%;
    min-width: 30rem;
    margin: 0rem 2rem 4rem 2rem;
}

</style>
