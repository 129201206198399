<template>
    <div id="app" v-if="isAppReady">
        <loader></loader>
        <header-wrapper/>
        <router-view/>
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import HeaderWrapper from '@/components/header/header.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import Loader from '@/components/loader/loader.vue';
import './main.less';
import { sdk } from '@/utils/sdk';

@Component({
    name: 'app',
    components: {
        HeaderWrapper,
        snackbar: Snackbar,
        loader: Loader,
    },
})
export default class App extends Vue {
    private isAppReady = false;

    private async created(): Promise<void> {
        await this.loadAuth();
    }

    private async loadAuth(): Promise<void> {
        const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
        await sdk?.core.userAccessManagement.setPermissions(useCaseId);
        this.isAppReady = true;
    }
}
</script>

<style lang="less">
</style>
