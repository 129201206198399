<template>
    <div class="loader-container" v-show="isLoading">
        <div class="loader-backdrop"></div>
        <div class="loader center-align">
            <div class="bar-container"><div class="bar"></div></div>
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Loader extends Vue {

    constructor() {
        super();
    }

    get isLoading(): boolean {
        return this.$store.getters.isLoading;
    }
}

</script>
<style scoped lang="less">
@import '../../variables.less';

.loader-container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 13;
    .loader-backdrop {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: #000;
        opacity: 0.2;
        z-index: 12;
    }
    .loader {
        width: 30%;
        height: 30%;
        max-height: 100px;
        max-width: 340px;
        z-index: 13;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        border-radius: 3px;
        h3 {
            color: #fff;
        }
        .bar-container {
            width: 80%;
            margin: auto;
            .bar {
                width: 5rem;
                height: 5rem;
                clear: both;
                margin: 0 auto;
                border: 8px rgba(0,167,245,0.15) solid;
                border-top: 8px #00a7f5 solid;
                border-left: 8px #00a7f5 solid;
                border-radius: 50%;
                animation: spin 0.8s linear infinite;
            }
        }
    }

    @keyframes left-to-right {
        0% { margin-left: -33% }
        100% { margin-left: 133% }
    }

    @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
}
</style>
