const timelapseChartConfig = (id: string): any => {
    return {
        chart: {
            id: `${id}timelapse`,
            type: 'area',
            brush:{
                target: id,
                enabled: true,
            },
            selection: {
                enabled: true,
            },
            animations: {
                enabled: false,
            },
        },
        legend: {
            show: false,
        },
        colors: [ '#5CBCF5', '#08A1EA', '#00E396', '#FEAF19' ],
        fill: {
            type: 'gradient',
            gradient: {
                stops: [ 0, 100 ],
                opacityFrom: 0,
                opacityTo: 0,
            },
        },
        xaxis: {
            type: 'datetime',
            labels: { datetimeUTC: false },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            labels: {
                formatter: function (val: string): string {
                    return val;
                },
            },
            tickAmount: 2,
            min: 0,
            forceNiceScale: true,
        },
        toolbar: {
            autoSelected: 'pan',
            show: false,
        },
    };
};

export default timelapseChartConfig;