<template>
<div class="multiselect">
    <button class="btn btn-filter" @click="toggleSelect()">
        <font-awesome-icon icon="angle-down" class="icon-angle-down"/>
        <template v-if="selectedOptions">
            <template v-for="option in selectedOptions">
                {{ option.name }}
            </template>
        </template>
    </button>
    <div v-if="showSelect" @click="showSelect = false" class="overlay"></div>
    <div id="dropdown-multiselect" v-if="showSelect">
        <div class="dropdown-menu dropdown-unit pb-0 show">
            <label class="menu-select-item" @click.stop="" v-for="(option,key) in options" :key="key">
                <label class="wrapper-checkbox">
                    <input type="checkbox" v-model="appliedOptions" :value="option" />
                    <span class="checkmark"></span>
                </label>
                {{option.name}}
            </label>
            <div class="sticky-position">
                <div class="dropdown-divider"></div>
                <div class="section-button">
                    <button class="btn btn-link" @click.stop="selectAllOptions()">{{$t('all')}}</button>
                    <button class="btn btn-link" @click.stop="clearOptions()">{{$t('clear')}}</button>
                    <button class="btn btn-link" :disabled="appliedOptions.length === 0" @click="applySelectedOptions()">{{$t('apply')}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { Unit } from '@/models';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
    name: 'multi-select-input',
})
export default class MultiselectInput extends Vue {
    /**
     * Values of the multiselect input.
     */
    @Prop()
    private options!: any[];

    private appliedOptions: any[] = [];

    private selectedOptions: any[] = [];

    private showSelect = false;

    @Watch('options')
    private onOptionChange(): void {
        this.selectedOptions = [...this.options];
    }

    @Watch('$route.path')
    private onPathChange(newVal: string, oldVal: string): void {
        if (newVal && newVal !== oldVal && this.$route.params.units) {
            this.selectedOptions = this.options.filter(u => this.$route.params.units.split(',').includes(u.id.toString()));
        }
    }

    private mounted(): void {
        this.selectedOptions = [];
        if (this.options) {
            this.selectedOptions = [...this.options];
            if (this.$route.params.units) {
                this.selectedOptions = this.options.filter(u => this.$route.params.units.split(',').includes(u.id.toString()));
            }
        }
    }

    private toggleSelect(): void {
        this.appliedOptions = [...this.selectedOptions];
        this.showSelect = !this.showSelect;
    }

    private selectAllOptions(): void {
        this.appliedOptions = [];
        this.options.forEach(p => {
            this.appliedOptions.push(p);
        });
    }

    private clearOptions(): void {
        this.appliedOptions = [];
    }

    private applySelectedOptions(): void {
        const unitsSid: number[] = [];
        this.selectedOptions = [...this.appliedOptions];
        this.selectedOptions.forEach((option: Unit) => {
            unitsSid.push(option.id);
        });
        this.$emit('apply-selected-options', unitsSid);
        this.showSelect = false;
    }
}
</script>

<style scoped lang="less">
.multiselect {
    z-index: 12;

    height: 3rem;
    .menu-select-item {
        display: flex;
        color: black;
        font-size: 1.6rem;
    }
    .overlay {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 99vw;
        height: 100vh;
    }
    .icon-angle-down {
        position: absolute;
        margin-top: 0.2rem;
        right: 0.3rem;
    }
    #dropdown-multiselect {
        position: relative;

        .dropdown-menu .menu-select-item {
            padding: 0.25rem 0.8rem;
        }
    }

    .fa-icon {
        float: right;
        top: -4px;
        right: -5px;
        position: relative;
    }

    .section-button {
        .btn {
            padding: 5px 15px;
            margin-top: 0;
            border: none;
            color: #007bff;
            background-color: transparent;
        }

        .btn-link:disabled {
            pointer-events: initial;
            opacity: 0.35;
        }

        .btn-link:hover {
            text-decoration: none;
        }

        .btn:nth-child(2) {
            position: relative;
            right: 0;
        }

        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
        background-color: #F3F5F7;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }

    .dropdown-divider {
        margin: unset;
        margin-top: 8px;
    }

    .dropdown-menu.dropdown-unit {
        overflow-y: auto;
        max-height: 40rem;
        min-width: 25rem;
        max-width: 30rem;
        top: -0.2rem;
        border: 1px solid #0868A3;
        border-radius: 0;
        padding-top: 1rem;
        position: absolute;
        background-color: #fff;
        right: 0.1rem;
        @media (max-width: 767px) {
            max-height: 28rem;
        }
    }

    .sticky-position {
        position: sticky;
        bottom: 0;
    }

    .active-filter.btn-primary {
        background-color: #0062cc;
        color: white;
    }

    .btn.btn-filter {
        border-radius: 0.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 25rem;
        height: 100%;
        white-space: nowrap;
        box-shadow: none !important;
        text-align: left;
        border: none;
        background-color: white;
        color: black;
        position: relative;

    }

    /* Hide the browser's default checkbox */
    .wrapper-checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        height: 1.7rem;
        width: 1.7rem;
        /* background-color: #eee; */
        border: 2px solid #d0d0d0;
        border-radius: 4px;
        top: unset;
        left: 0.4rem;
    }

    /* When the checkbox is checked, add a blue background */
    .wrapper-checkbox input:checked~.checkmark {
        background-color: #2196F3;
        border: 2px solid #2196F3;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .wrapper-checkbox input:checked~.checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .wrapper-checkbox .checkmark:after {
        left: 0.5rem;
        top: 0px;
        width: 0.4rem;
        height: 1rem;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .btn {
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;

    }

    label.wrapper-checkbox {
       margin-left: 4rem;
        color: black;
    }
}
</style>
