<template>
    <div class="paging mt-1 mb-1"
        v-if="paging.pages.length > 0">
        <button type="button"
            :class="{'disabled': paging.currentPage === 1}"
            @click="previousPage()">
            <font-awesome-icon icon="angle-left" />
        </button>
        <span v-for="page of paging.pages"
            :key="page">
            <button type="button"
                v-if="isPageToShow(page)"
                :class="{'current': page === paging.currentPage}"
                @click="paginate(page)">
                {{page}}
            </button>
            <button type="button"
                v-else-if="!hasAppliedEllipsisBefore(page)"
                class="ellipsis" disabled>
                ...
            </button>
        </span>
        <button type="button"
            :class="{'disabled': paging.currentPage === paging.numberOfPages}"
            @click="nextPage()">
            <font-awesome-icon icon="angle-right" />
        </button>
    </div>
</template>
<script lang="ts">

import { PagingModel } from '@/models';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'paging-component',
})
export default class PagingComponent extends Vue {
    /*
    ** This prop is required to know current page and page numbers.
    */
    @Prop({ default: () => {
        return {
            currentPage: 1,
            numberOfPages: 0,
            pages: [],
        };
    }})
    private paging!: PagingModel;

    private previousPage(): void {
        if (this.paging.currentPage - 1 > 0) {
            --this.paging.currentPage;
            this.$emit('paginate', this.paging.currentPage);
        }
    }

    private paginate(page: number): void {
        this.paging.currentPage = page;
        this.$emit('paginate', page);
    }

    private nextPage(): void {
        if (this.paging.currentPage + 1 <= this.paging.numberOfPages) {
            ++this.paging.currentPage;
            this.$emit('paginate', this.paging.currentPage);
        }
    }

    private isPageToShow(page: number): boolean {
        return (page === 1 || page === this.paging.currentPage ||
            page === this.paging.numberOfPages || this.isNeighbourToSelectedPage(page));
    }

    private isNeighbourToSelectedPage(page: number): boolean {
        return (this.paging.currentPage - page === 1 || this.paging.currentPage - page === 2 ||
            page - this.paging.currentPage === 1 || page - this.paging.currentPage === 2);
    }

    private hasAppliedEllipsisBefore(page: number): boolean {
        const array = page <= this.paging.currentPage ?
            this.paging.pages.slice(0, page) : this.paging.pages.slice(this.paging.currentPage - 1, page);
        return array.filter((a: number) => !this.isPageToShow(a)).length > 1;
    }
}

</script>
<style scoped lang="less">
@import '../../variables.less';
.paging {
    button {
        background-color: @white;
        color: @uniper-blue;
        padding: 0.8rem 1.28rem;
        border: 1px solid @lighter-grey;
        font-size: 1.6rem;
        &.disabled {
            background-color: @warm-grey;
            color: black;
        }
        &.current, &:hover {
            background-color: @uniper-blue;
            color: @white;
        }
        &.ellipsis {
            padding: 0.8rem 1.6rem;
            cursor: auto;
            &.disabled, &:hover {
                background-color: @white;
                color: @uniper-blue;
            }
        }
    }
}
</style>
