import { COODE_DEFAULT_URLS, CoodeSdk, CoodeSdkOptions, Environments } from '@coode/fe-sdk';

const coodeSdkPluginConfiguration: CoodeSdkOptions = {
    environment: process.env.VUE_APP_ENVIRONMENT as Environments,
    urls: {
        ...COODE_DEFAULT_URLS,
        masterData: {
            development: {
                url: 'https://app-coode-master-data-feed-mi-api-dev.azurewebsites.net/api/v1.1/',
                scope: 'api://e37e2af7-016e-464c-926f-555a3741c2e2/.default'
            },
            dev: {
                url: 'https://app-coode-master-data-feed-mi-api-dev.azurewebsites.net/api/v1.1/',
                scope: 'api://e37e2af7-016e-464c-926f-555a3741c2e2/.default'
            },
            uat: {
                url: 'https://app-coode-master-data-feed-mi-api-uat.azurewebsites.net/api/v1.1/',
                scope: 'api://48fa1515-fbc4-4818-9bdb-46689669bf66/.default'
            },
            prd: {
                url: 'https://app-coode-master-data-feed-mi-api-prd.azurewebsites.net/api/v1.1/',
                scope: 'api://2a867901-c7b1-4e61-a6b4-9890c6cb4d6d/.default'
            },
        },
    },
    auth: {
        backEndBaseUrl: process.env.VUE_APP_API_BASE_URL,
        backEndScope: process.env.VUE_APP_LIVE_GEN_SCOPE,
        msal: {
            auth: {
                clientId: process.env.VUE_APP_CLIENT_ID || '',
                authority: process.env.VUE_APP_AUTHORITY_URL,
                redirectUri: window.location.origin,
                postLogoutRedirectUri: `${window.location.origin}/authorization/logged-out.html`,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
            },
            system: {
                loggerOptions: 0 as any,
            },
        },
    },
    insights: {
        appInsightsConnectionString: process.env.VUE_APP_APP_INSIGHTS_CONNECTION_STRING,
    }
};

export const sdk = new CoodeSdk(coodeSdkPluginConfiguration);
