<template>
    <select
        class="select"
        v-model="selectedOption"
        @change="onChangeHandler">
        <option
            v-for="option of options"
            :key="option.id"
            :value="option">
            {{option.name}}
        </option>
    </select>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
    name: 'select-input',
})
export default class SelectInput extends Vue {
    /**
     * Value of the select input.
     */
    @Prop()
    private options!: any[];

    /**
     * Default value of the select input.
     */
    @Prop()
    private defaultOption!: number | null;

    private selectedOption: any = [];

    @Watch('defaultOption')
    private onOptionChange(): void {
        this.setDefaultSelectedOption();
    }

    private mounted(): void {
        this.setDefaultSelectedOption();
    }

    private setDefaultSelectedOption(): void {
        this.selectedOption = [];
        if (this.options && !this.defaultOption) {
            this.selectedOption = this.options[0];
        } else if (this.defaultOption) {
            this.selectedOption = this.options.filter(o => Number(o.id) === Number(this.defaultOption))[0];
        }
    }

    private onChangeHandler(): void {
        this.$emit('on-change', this.selectedOption.id);
    }
}
</script>

<style scoped lang="less">
.select {
    border-radius: 0.6rem;
    margin: 0 0.5rem;
    display: inline-block;
    min-width: 15rem;
    line-height: 1.5;
    color: black;
    vertical-align: middle;
    background-size: 8px 10px;
    border: none;
    background-color: white;
    height: 3rem;
    font-weight: 500;
    font-size: 1.6rem;
    @media (max-width: 1199px) {
        min-width: auto;
    }

    @media (max-width: 768px) {
        padding: 0 5px;
    }
    option {
        background-color: white;
        color: black;
        padding: 20px;

    }

    option:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: red;
    }

    &:focus {
        border: none;
        outline: none;
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: white;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: white;
    }
}
</style>
