<template>
    <div class="last-refreshed">
        {{ $t('lastRefreshed') }}&nbsp;{{ minutes }}<span class="bold-text">{{ $t('min') }}</span>:{{ seconds }}<span class="bold-text">s</span>&nbsp;{{ $t('ago') }}
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'timer',
})
export default class Timer extends Vue {
    /**
     * Default timer value in ms.
     */
    @Prop()
    private value!: number;

    private time = 0;
    private timer: any = 0;

    private incrementTimer(): void {
        if (this.time < this.value) {
            this.time++;
            return;
        }
        this.$emit('reload');
        this.time = 0;
    }

    get minutes(): string {
        return String(Math.floor(this.time / 60)).padStart(2, '0');
    }

    get seconds(): string {
        return String(this.time % 60).padStart(2, '0');
    }

    private mounted(): void {
        this.timer = setInterval(this.incrementTimer, 1000);
    }

    private resetTimer(): void {
        this.time = 0;
    }

}
</script>

<style lang="less" scoped>
.last-refreshed {
    font-size: 1.8rem;
    padding: 2rem 0;
    .bold-text {
        font-family: Roboto-Medium, sans-serif;
    }
}
</style>
