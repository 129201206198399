import Vue from 'vue';
import App from './App.vue';
import VueI18n from 'vue-i18n';
import router from './router';
import store from './store';
import 'adam.ui-core/dist/umd';
import 'adam.ui-core/utils/scaling.css';
import './registerServiceWorker';
import { VNode } from 'vue/types/umd';
import VueApexCharts from 'vue-apexcharts';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAngleDown, faExclamationTriangle, faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons';
import { sdk } from '@/utils/sdk';
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(VueApexCharts);
Vue.use(PebbleUI);
Vue.component('apex-chart', VueApexCharts);
Vue.component('font-awesome-icon', FontAwesomeIcon);

library.add(faAngleDown as any, faExclamationTriangle as any, faAngleLeft as any, faAngleRight as any);

const lang = 'en-GB';
const i18n = new VueI18n({
    locale: lang,
    messages: require(`./locales/${lang}.json`),
});

sdk?.auth.init().then(() => {
    new Vue({
        i18n,
        router,
        store,
        render: (h): VNode => h(App),
    }).$mount('#app');
})
