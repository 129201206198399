import { ApiResponse, GenerationData} from '@/models';
import { sdk } from '@/utils/sdk';

export class GenerationDataService {
    public async getGenerationData(
        unitIds: number[],
        daysBefore = 1,
        daysAfter = 2
    ): Promise<ApiResponse<GenerationData[]>> {
        let query = `?daysBefore=${daysBefore}&daysAfter=${daysAfter}`
        if (unitIds) {
            unitIds.forEach((unitId, index) => {
                if (index === 0) {
                    query += '&'
                }
                query += `unitIds=${unitId}`
                if (index >= 0 && index < unitIds.length - 1) {
                    query += '&'
                }
            })
        }
        return (
            await sdk?.backend.request.api.get<ApiResponse<GenerationData[]>>(
                `generation-data${query}`,
            )
        ).data
    }
}
