import { ApiResponse, Country, Unit } from '@/models';
import { sdk } from '@/utils/sdk';

export class MasterDataService {

    public async getCountry(): Promise<ApiResponse<Country[]>> {
        return (await sdk?.core.masterData.request.api.get<ApiResponse<Country[]>>(
            `/countries/module/${process.env.VUE_APP_MODULE_ASSET_STREAM_ID}`)
        ).data;
    }

    public async getUnits(countrySid?: number): Promise<ApiResponse<Unit[]>> {
        return (await sdk?.core.masterData.request.api.get<ApiResponse<Unit[]>>(
            `/units/module/${process.env.VUE_APP_MODULE_ASSET_STREAM_ID}`,
            {
                params: {
                    countryId: countrySid
                }
            })
        ).data;
    }
}
